export enum EDITIONS_ENUM {
  FREE = 'free',
  ENTERPRISE = 'ent',
  PRO = 'pro'
}

export interface Licensor {
  name: string;
  contact: string[];
  issuer: string;
}

export interface Licensee {
  cluster_uuid: string;
  name: string;
  buying_for?: string | null;
  billing_email?: string | null;
  alt_emails: string[];
  address: string[];
}

export interface License {
  edition: EDITIONS_ENUM | string;
  edition_name: string;
  isTrial: boolean;
}

export interface ActivationKey {
  exp: number;
  iss: string;
  iat: number;
  jti: string;
  aud: string;
  sub: string;
  licensor: Licensor;
  licensee: Licensee;
  license: License;
}

export type ActivationKeyRetrievalMode = 'Api' | 'Env' | 'File' | 'All' | 'None';

export interface ActivationKeyConfig {
  retrieval_modes: ActivationKeyRetrievalMode[];
  refresh_interval: string;
  default_activation_key: string;
  token_public_key: string;
  trial_url: string;
  active_subscriber_activation_key_url: string;
}

export type ActivationKeyOrigin = (ActivationKeyRetrievalMode | 'default_key') | null;

export type ActivationKeyWithOrigin = ActivationKey & {
  origin: ActivationKeyOrigin;
};

export const ACTIVATION_KEY_ENV_VAR = 'ACTIVATION_KEY';
