import { errorResponse } from 'shared/responses';
import { isUnauthorizedError } from './UnauthorizedError';

export async function handleResponse<T = Object>(resp: Response): Promise<AuthfishResponse<T>> {
  let respData;
  try {
    respData = await resp.json();
  } catch (error) {
    respData = { error: `error parsing response: ${error}` };
  }
  if (resp.status === 200) {
    return {
      success: true,
      response: respData,
      status: 200
    };
  } else {
    return {
      success: false,
      response: respData,
      status: resp.status
    };
  }
}

export function handleError(error: unknown): AuthfishResponse<errorResponse> {
  console.warn(error);
  if (error instanceof Error) {
    return {
      success: false,
      response: { error: error.message },
      status: isUnauthorizedError(error) ? 401 : 500
    };
  } else
    return {
      success: false,
      response: { error: `Unknown error ${error}` },
      status: 500
    };
}

export interface AuthfishResponse<T> {
  success: boolean;
  // we could get success response or object with error
  response: T;
  status: number;
}
