import React from 'react';
import { Menu } from 'antd';
import {
  SettingOutlined,
  CloudServerOutlined,
  LockOutlined,
  UsergroupAddOutlined,
  SignatureOutlined
} from '@ant-design/icons';
import getMenuItems, { labelMapping, OnItemClickHandler } from './helpers/getMenuItems';
import { FieldCategories } from '../../types/FormFields';
import { ProtectedSettings } from 'shared/AuthfishParams';

const authLabelsMapping: labelMapping<FieldCategories>[] = [
  { keysToHide: ['auth.local'], category: 'Local' },
  { keysToHide: ['auth.ldap'], category: 'LDAP' },
  { keysToHide: ['auth.saml'], category: 'SAML' },
  { keysToHide: ['auth.oidc'], category: 'OIDC' }
];
export const labelsMapping: labelMapping<FieldCategories>[] = [
  {
    keysToHide: ['auth.enabled', 'auth.strategies', 'public_routes', 'branding', 'log_level'],
    category: 'General',
    icon: <SettingOutlined />
  },
  {
    keysToHide: ['session', 'https', 'host', 'port', 'proxy'],
    category: 'Server',
    icon: <CloudServerOutlined />
  }, //  (http, certs)
  { category: 'Activation key', icon: <SignatureOutlined /> },
  { keysToHide: ['auth'], category: 'Auth', icon: <LockOutlined />, children: authLabelsMapping },
  // _TODO: temp disabled Roles, need to add it
  { category: 'Roles', icon: <UsergroupAddOutlined />, isDisabled: true }
];

interface SiderComponentProps {
  onItemClickHandler: OnItemClickHandler<FieldCategories>;
  protectedSettings: ProtectedSettings[];
}

const SiderComponent: React.FC<SiderComponentProps> = ({
  onItemClickHandler,
  protectedSettings
}) => {
  // light theme should be defined here + for Sider in ConfigPage
  return (
    <Menu
      theme="light"
      // onClick={...} // here we only can get item key
      defaultSelectedKeys={['menu_item_1']}
      defaultOpenKeys={['menu_item_3']}
      mode="inline"
      items={getMenuItems(labelsMapping, undefined, onItemClickHandler, protectedSettings)}
    />
  );
};

export default React.memo(SiderComponent);
