import React, { useMemo, useState } from 'react';
import { getActivationKey } from '../../../api/handleActivationKey';
import { suspend } from 'suspend-react';
import { Button, Col, Row, Typography } from 'antd';
import styles from './ActivationKeyManagement.module.scss';
import { LoadActivationKeyModal } from './LoadActivationKeyModal';
import { DeleteActivationKeyModal } from './DeleteActivationKeyModal';
import { NotifyAndLogoutModal } from './NotifyAndLogoutModal';
import { ActivationKeyOrigin } from 'shared/activationKey';
import { configSourceData } from '../../../api/getConfigHandler';
import { ActivationKeyMethodsDescription } from './ActivationKeyMethodsDescription';
import { AuthfishStaticConfig } from 'shared/authfishStaticConfig';

export function toDate(n: number): Date {
  return new Date(n * 1000);
}

function withRedirectParam(url: string) {
  const newUrl = new URL(url);
  newUrl.searchParams.set('redirect', window.location.href);

  return newUrl.toString();
}
interface Props {
  staticConfig: AuthfishStaticConfig;
  configSourceObj: configSourceData | undefined;
}

export const ActivationKeyManagement = ({ staticConfig, configSourceObj }: Props) => {
  const [showLoadActivationModal, setShowLoadActivationModal] = useState(false);
  const [showDeleteActivationModal, setShowDeleteActivationModal] = useState(false);
  const [showNotifyAndLogoutModal, setShowNotifyAndLogoutModal] = useState(false);

  const handleCloseLoadActivationModal = () => {
    setShowLoadActivationModal(false);
  };
  const handleOpenLoadActivationModal = () => {
    setShowLoadActivationModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteActivationModal(false);
  };
  const handleOpenDeleteModal = () => {
    setShowDeleteActivationModal(true);
  };

  const handleOpenNotifyAndLogoutModal = () => {
    setShowNotifyAndLogoutModal(true);
  };

  const { data: activationKey } = suspend(getActivationKey);

  const difference_ms = useMemo(
    () => toDate(activationKey.exp).getTime() - new Date().getTime(),
    [activationKey.exp]
  );
  const difference_days = useMemo(
    () => Math.round(difference_ms / (1000 * 3600 * 24)),
    [difference_ms]
  );

  const items = useMemo(() => {
    const availableItems = [
      { title: 'Activation Key ID', description: activationKey.jti },
      { title: 'Edition', description: activationKey.license.edition_name },
      { title: 'Licensed to', description: activationKey.licensee.name }
    ];
    if (activationKey.licensee.buying_for) {
      availableItems.push({ title: 'Buying for', description: activationKey.licensee.buying_for });
    }
    availableItems.push(
      { title: 'Created', description: `${toDate(activationKey.iat).toDateString()}` },
      {
        title: 'Expires',
        description: `in ${difference_days} days (${toDate(activationKey.exp).toDateString()})`
      },
      { title: 'Loaded from', description: activationKey.origin ?? '-' }
    );

    return availableItems;
  }, [
    difference_days,
    activationKey.exp,
    activationKey.iat,
    activationKey.jti,
    activationKey.license.edition_name,
    activationKey.licensee.buying_for,
    activationKey.licensee.name,
    activationKey.origin
  ]);

  const isDeleteKeyActivationKeyDisabled = useMemo(() => {
    const validOrigins: ActivationKeyOrigin[] = ['Api'];

    return !validOrigins.includes(activationKey.origin);
  }, [activationKey.origin]);

  return (
    <>
      <Typography.Title>Activation key management</Typography.Title>
      <div className={styles.activation_key_wrapper}>
        {items.map((item, index) => (
          <Row gutter={16} key={index} className={styles.activation_key_row}>
            <Col span={6}>
              <Typography.Text strong>{item.title}:</Typography.Text>
            </Col>
            <Col span={18}>
              <Typography.Text>{item.description}</Typography.Text>
            </Col>
          </Row>
        ))}
      </div>

      <Row gutter={12}>
        <Col>
          <Button type="primary" onClick={handleOpenLoadActivationModal}>
            Load Activation Key
          </Button>
        </Col>
        <Col>
          <Button
            danger
            disabled={isDeleteKeyActivationKeyDisabled}
            onClick={handleOpenDeleteModal}
          >
            Delete
          </Button>
        </Col>
        <Col
          className={styles.activation_key_license_link_wrapper}
          style={{ marginBottom: '16px' }}
        >
          <Button
            className={styles.activation_key_license_link_button}
            type="link"
            target="_blank"
            href={withRedirectParam(
              staticConfig.activation_key.active_subscriber_activation_key_url
            )}
          >
            Obtain an active subscriber's Activation Key
          </Button>
          <Button
            className={styles.activation_key_license_link_button}
            type="link"
            target="_blank"
            href={withRedirectParam(staticConfig.activation_key.trial_url)}
          >
            Obtain a Trial Activation Key
          </Button>
        </Col>
      </Row>
      <ActivationKeyMethodsDescription />

      <LoadActivationKeyModal
        open={showLoadActivationModal}
        handleClose={handleCloseLoadActivationModal}
        handleNotifyAndLogout={handleOpenNotifyAndLogoutModal}
        configSourceObj={configSourceObj}
      />
      <DeleteActivationKeyModal
        open={showDeleteActivationModal}
        handleClose={handleCloseDeleteModal}
        handleNotifyAndLogout={handleOpenNotifyAndLogoutModal}
        configSourceObj={configSourceObj}
      />
      <NotifyAndLogoutModal
        open={showNotifyAndLogoutModal}
        message={'Activation Key successfully changed'}
      />
    </>
  );
};
