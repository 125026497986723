import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Layout, App as AntdApp, Spin } from 'antd';
import { HookAPI } from 'antd/lib/modal/useModal';

// may move this to the index | App if will need other pages inside iFrame
import 'iframe-resizer/js/iframeResizer.contentWindow.js';

import { AuthfishConfig } from 'shared/authfishConfig';
import { LOGOUT_ROUTE } from 'shared/routes';
import type { ProtectedSettings } from 'shared/AuthfishParams';
import type User from 'shared/User';
import SiderComponent from '../../components/config/SiderComponent';
import { FieldCategories } from '../../types/FormFields';
import PageHeader from '../../components/PageHeader';
import ConfigForm from '../../components/config/ConfigForm';
import afLogoutRedirect from '../../components/config/helpers/afLogoutRedirect';
import getConfigHandler, { configSourceData } from '../../api/getConfigHandler';
import UserMenu from '../../components/config/UserMenu';

import styles from './ConfigPage.module.scss';
import { AuthfishStaticConfig } from 'shared/authfishStaticConfig';

const { Sider, Content } = Layout;

const reloadPage = () => window.location.reload();

const errorModal = (errorMsg: string, modal: HookAPI, permissionsIssue: boolean = false) => {
  modal.error({
    title: 'Error',
    content: errorMsg,
    afterClose: permissionsIssue ? afLogoutRedirect : reloadPage
  });
};

const ConfigPage = () => {
  const [activeLabel, setActiveLabel] = useState<FieldCategories>('General');

  const [config, setConfig] = useState<AuthfishConfig | null>(null);

  const [staticConfig, setStaticConfig] = useState<AuthfishStaticConfig | null>(null);

  const [user, setUser] = useState<User | null>(null); // [user, setUser]

  const [loading, setLoading] = useState<boolean>(true);

  const [timestampsAvailable, setTimestampsAvailable] = useState<number[]>([]);

  const [protectedSettings, setProtectedSettings] = useState<ProtectedSettings[]>([]);

  const [sourceData, setSourceData] = useState<configSourceData | undefined>(undefined);

  const hasEffectRun = useRef<boolean>(false);

  const { modal } = AntdApp.useApp();

  const onItemClickHandler = useCallback((label: FieldCategories) => {
    setActiveLabel(label);
  }, []);

  useEffect(() => {
    // useRef to prevent double render in strict mode
    if (hasEffectRun.current) return;
    hasEffectRun.current = true;
    setLoading(true);
    getConfigHandler().then((resp) => {
      const {
        config,
        staticConfig,
        errorMsg,
        status,
        timestampsAvailable, // stringified timestamps
        protectedSettings,
        sourceData,
        user
      } = resp;
      setConfig(config);
      setStaticConfig(staticConfig);
      if (timestampsAvailable) {
        setTimestampsAvailable(timestampsAvailable.map((timestamp) => parseInt(timestamp)));
      }
      if (protectedSettings) setProtectedSettings(protectedSettings);
      if (user) setUser(user);
      setSourceData(sourceData);
      // if the user has no admin permissions, the status will be 200
      const redirectURL = status === 401 ? LOGOUT_ROUTE : undefined;
      if (redirectURL) afLogoutRedirect(redirectURL);
      const permissionsIssue = (errorMsg && errorMsg.includes('permission')) || false;
      if (errorMsg) errorModal(errorMsg, modal, permissionsIssue);
      setLoading(false);
    });
  }, [modal]);

  if (!config || !staticConfig) return <></>;

  const addTimestamp = (newTimestamp: number): void => {
    setTimestampsAvailable((prevTimestamps) =>
      prevTimestamps.includes(newTimestamp) ? prevTimestamps : [newTimestamp, ...prevTimestamps]
    );
  };

  // light theme should be also applied in SiderComponent Menu
  return (
    <>
      <PageHeader pageTitle={'Config'} branding={config?.branding} />
      <Layout className={styles.layout}>
        <Sider className={styles.sider} theme={'light'}>
          <SiderComponent
            onItemClickHandler={onItemClickHandler}
            protectedSettings={protectedSettings}
          />
          <UserMenu user={user} />
        </Sider>
        <Layout>
          <Content className={styles.content}>
            <Spin size={'large'} spinning={loading}>
              {config && (
                <ConfigForm
                  config={config}
                  staticConfig={staticConfig}
                  setConfig={setConfig}
                  activeLabel={activeLabel}
                  timestampsAvailable={timestampsAvailable}
                  addTimestamp={addTimestamp}
                  protectedSettings={protectedSettings}
                  configSourceObj={sourceData}
                />
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default ConfigPage;
