import { ConfigSource, isGetConfigResponse } from 'shared/responses';
import { AuthfishConfig } from 'shared/authfishConfig';
import type { ProtectedSettings } from 'shared/AuthfishParams';
import type User from 'shared/User';
import { getConfig } from './handleConfig';
import { AuthfishStaticConfig } from 'shared/authfishStaticConfig';

export interface configSourceData {
  libraryMode: boolean;
  source?: ConfigSource;
  dbPath?: string;
}

interface getConfigHandlerResponse {
  config: AuthfishConfig | null;
  staticConfig: AuthfishStaticConfig | null;
  errorMsg: string | null;
  status: number;
  timestampsAvailable?: string[];
  protectedSettings?: ProtectedSettings[];
  sourceData?: configSourceData;
  user?: User;
}

async function getConfigHandler(createdAtTS?: string): Promise<getConfigHandlerResponse> {
  const { success, response: configResp, status } = await getConfig(createdAtTS);
  // success could be falsy & also, for current use case of getConfig
  // we need the user to be allowed to update the config
  if (success && isGetConfigResponse(configResp) && configResp.editPermission) {
    return {
      config: configResp.config,
      staticConfig: configResp.staticConfig,
      errorMsg: null,
      status,
      timestampsAvailable: configResp.timestampsAvailable,
      protectedSettings: configResp.protectedSettings,
      sourceData: {
        libraryMode: configResp.libraryMode,
        source: configResp.source,
        dbPath: configResp.dbPath
      },
      user: configResp.user || undefined
    };
  } else if (success) {
    return {
      config: null,
      staticConfig: null,
      errorMsg: "You don't have permission to edit the config",
      status
    };
  } else {
    return {
      config: null,
      staticConfig: null,
      errorMsg: 'error' in configResp ? configResp.error : JSON.stringify(configResp),
      status
    };
  }
}

export default getConfigHandler;
