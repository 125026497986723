import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import type { Callbacks } from 'rc-field-form/lib/interface';
import { saveActivationKey } from '../../../api/handleActivationKey';
import { configSourceData } from '../../../api/getConfigHandler';
import { useModalActions } from './useModalActions';

interface FieldType {
  activationKey?: string;
}

interface Props {
  open: boolean;
  handleClose: () => void;
  handleNotifyAndLogout: () => void;
  configSourceObj: configSourceData | undefined;
}

export const LoadActivationKeyModal = ({
  open,
  handleClose,
  handleNotifyAndLogout,
  configSourceObj
}: Props) => {
  const [form] = Form.useForm();

  const { loading, handleModalAction } = useModalActions<typeof saveActivationKey>({
    handleClose,
    handleNotifyAndLogout,
    libraryMode: configSourceObj?.libraryMode,
    action: (value) => saveActivationKey(value)
  });

  const handleSubmit: Callbacks['onFinish'] = async (dataTestID: string) => {
    await form.validateFields().then(async (value) => handleModalAction(value));
  };

  return (
    <Modal
      title="Load Activation Key"
      open={open}
      onOk={handleSubmit}
      onCancel={loading ? undefined : handleClose}
      okButtonProps={{ title: 'Activate' }}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            Activate
          </Button>
        </>
      )}
    >
      <Form form={form} name="activationKeyForm" layout="vertical" autoComplete="off">
        <Form.Item<FieldType>
          label="Paste your Activation Key here"
          name="activationKey"
          rules={[{ required: true, message: 'Please input your Activation key' }]}
        >
          <Input.TextArea
            autoSize
            onInput={function (event) {
              event.preventDefault();

              const target = event.target as HTMLInputElement;
              target.value = target.value.trim();
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
